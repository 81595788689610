import React, { useState } from "react";

const Contacts = () => {
  const [isCopied, setIsCopied] = useState(false);
  const email = "evkmelnikov.a@gmail.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email).then(
      () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Hide the message after 2 seconds
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <section className="mt-40 mb-5 overflow-x-hidden transition-colors duration-200">
        <div className="text-center">
          <h2 className="text-3xl sm:text-4xl md:text-5xl dark:text-neutral-300 font-bold mb-20">
            <span className="border-b-4 border-[#7e22ce]">
              Contacts
            </span>
          </h2>
      <div>
        {/* Socials Container */}
        <ul className="flex justify-center dark:text-neutral-300 space-x-10 sm:space-x-20">
          {/* GitHub */}
          <li className="flex-basis-1/5 text-center">
            <a
              href="https://github.com/eskimosa"
              target="_blank"
              rel="noopener noreferrer"
              aria-hidden="true"
              title="Go to Evgenia's Github"
              className="group"
            >
              <span
                className="text-4xl group-hover:scale-110 group-hover:text-[#7e22ce] transition-transform duration-300"
                tabIndex="0"
              >
                <i className="fab fa-github"></i>
              </span>
              <p className="text-xs font-light mt-0.5 leading-normal text-black dark:text-white group-hover:text-[#7e22ce]">
                GitHub
              </p>
            </a>
          </li>

          {/* LinkedIn */}
          <li className="flex-basis-1/5 text-center">
            <a
              href="https://www.linkedin.com/in/evgeniya-melnikova/"
              target="_blank"
              rel="noopener noreferrer"
              aria-hidden="true"
              title="Go to Evgenia's LinkedIn"
              className="group"
            >
              <span
                className="text-4xl group-hover:scale-110 group-hover:text-[#7e22ce] transition-transform duration-300"
                tabIndex="0"
              >
                <i className="fab fa-linkedin"></i>
              </span>
              <p className="text-xs font-light mt-0.5 leading-normal text-black dark:text-white group-hover:text-[#7e22ce]">
                LinkedIn
              </p>
            </a>
          </li>

 	{/* Email */}

          <li className="relative text-center">
              <button
                onClick={copyToClipboard}
                aria-hidden="true"
                title="evkmelnikov.a@gmail.com"
                className="group relative"
              >
                <span className="text-4xl group-hover:scale-110 group-hover:text-[#7e22ce] transition-transform duration-300">
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <p className="text-xs font-light mt-0.5 leading-normal text-black dark:text-white group-hover:text-[#7e22ce]">
                  Email
                </p>
              </button>
              {/* Display the "Email copied!" message */}
              {isCopied && (
                <span className="absolute -top-12 left-1/2 transform -translate-x-1/2 bg-[#7e22ce] text-white px-3 py-1 text-xs sm:text-sm rounded-lg animate-fade-in-out">
                  Email copied!
                </span>
              )}
            </li>
        </ul>
      </div>
      </div>
    </section>
  );
};

export default Contacts;
