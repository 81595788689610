import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav>
      <div className="mx-auto max-w-full sm:max-w-7xl px-2 sm:px-4 lg:px-8">
        <div className="flex h-12 items-center justify-between">
          <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
            <div className="flex flex-1 justify-center sm:justify-end">
              <div className="flex space-x-2 sm:space-x-4 justify-end">
                <a href="#home" className="text-sm sm:text-base dark:text-neutral-300 font-bold border-b-4 border-transparent hover:border-[#7e22ce] cursor-pointer px-2 sm:px-3 py-2">
                  Home
                </a>
                <a href="#about" className="text-sm sm:text-base dark:text-neutral-300 font-bold border-b-4 border-transparent hover:border-[#7e22ce] cursor-pointer px-2 sm:px-3 py-2">
                  About
                </a>
                <a href="#projects" className="text-sm sm:text-base dark:text-neutral-300 font-bold border-b-4 border-transparent hover:border-[#7e22ce] cursor-pointer px-2 sm:px-3 py-2">
                  Projects
                </a>
                <a href="#contacts" className="text-sm sm:text-base dark:text-neutral-300 font-bold border-b-4 border-transparent hover:border-[#7e22ce] cursor-pointer px-2 sm:px-3 py-2">
                  Contacts
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
